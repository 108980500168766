<template>
  <transition name="sf-fade" mode="out-in" :duration="80">
    <button
      v-if="showLink"
      class="scroll-to-top title-h5 s:text-sm"
      :class="{ lifted: lifted }"
      @click="scrollToTop"
    >
      {{ $t('Back to top') }}
      <VaimoIcon
        icon="arrowup_gris_perle"
        :size="15"
        class="icon align-text-bottom ml-2px"
        :label="$t('Back to top')"
      />
    </button>
  </transition>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref
} from '@nuxtjs/composition-api';
import throttle from 'lodash.throttle';

export default defineComponent({
  name: 'VaimoScrollToTop',
  props: {
    lifted: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const showLink = ref(false);
    const SCROLL_THROTTLE = 25;

    const handleScroll = throttle(
      () => {
        showLink.value = window.scrollY > 0;
      },
      SCROLL_THROTTLE,
      { leading: true }
    );

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    onMounted(() => {
      if (process.client) {
        window.addEventListener('scroll', handleScroll);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      showLink,
      scrollToTop
    };
  }
});
</script>

<style lang="scss" scoped>
.scroll-to-top {
  @apply z-decoration;
  padding: 2px 6px 1px 10px;
  outline: none;
  height: 22px;
  border: 0.8px solid $gray_light;
  border-radius: 22px;
  position: fixed;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $blanc;
  color: $gris_perle;
  text-transform: uppercase;
  @include for-mobile {
    bottom: var(--spacer-md);
  }

  &.lifted {
    @include for-mobile {
      bottom: 80px;
    }
  }
}
</style>
